import React, { useState, useEffect } from "react";
import Header from "../../../../components/header/header";
import Content from "../../../../components/main/content/DynamicContent";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/footer";
import { graphql } from "gatsby";
import "./loader.scss";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  return (
    <>
      <div className="layout">
        <Header
          category={data?.cms?.categories}
          domain={data?.cms?.domain}
          allPosts={allPosts}
          engine={options}
        />
        <div className="container is-max-widescreen">
          <div className="columns m-0">
            <Content story={data?.cms?.CMS_List} domain={data?.cms?.domain} />
            <Sidebar recent_story={data?.cms?.recent_story} />
          </div>
        </div>
        <Footer
          pages={data?.cms?.pages}
          domain={data?.cms?.domain}
          email={"steven@finalscope.com"}
        />
      </div>
    </>
  );
};
export default ArticlePage;

export const query = graphql`
  query listicle($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "12") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "12") {
        id
        image
        name
        slug
      }
      CMS_List(id: $slug) {
        image
        title
        slug
        description
        bottom_line
        cta_button_type
        categories {
          name
        }
        blocks {
          image
          title
          description
        }
        products {
          id
          image
          name
          description
          product_cons
          product_pros
          stores {
            id
            name
          }
        }
      }
      recent_story: CMS_DomainWiseStories(domain_id: "12") {
        id
        image
        pub_date
        slug
        sub_title
        title
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
